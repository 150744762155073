<template>
    <v-container fluid>
        <headerVue :title="$t('titles.equipmentType')" />
        <v-row align-content="center" justify="end">
            <v-col md="3">
                <a href="/agregartipoequipo">
                    <v-btn block color="primary" elevation="2" x-large>Crear Tipo de equipo</v-btn>
                </a>
            </v-col>
            <v-col md="3" offset-md="6">
                <v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        <v-row>

			<v-col cols="12" style="text-align:center;">
				<v-progress-circular v-if="loading"
				:size="70"
				:width="7"
				color="purple"
				indeterminate></v-progress-circular>

				<v-alert v-if="deleteSuccess" type="success">El tipo de equipo fue eliminado</v-alert>
			</v-col>			

            <v-col v-if="!loading" cols="12">

                <v-data-table
                    :headers="headers"
                    :items="equipmentType"
                    class="elevation-2 text--dark table-striped"
                    loading-text="Cargando datos... Por favor espere"
                    :items-per-page="5"
                    :search="search"
                    :no-results-text="'No se encontraron tipos de equipo'"
                    :no-data-text="'No hay datos'"
                    :footer-props="{
                    'items-per-page-text': 'Tipos de equipo por página',
                    'items-per-page': [5, 10, 15],
                    'items-per-page-all-text': 'Todos',
                    'items-per-page-options': [5, 10],
                    'loading-text': 'Obteniendo datos... Por favor espere',
                    'no-data-text': 'No hay datos...',
                    'no-results-text': 'No se encontraron tipos de equipo',
                    }">
						
                    <template v-slot:no-data>
                        <v-alert :value="true" color="#ffffff">
                            <p style="color: #ff0000">No hay tipos de equipo registrados...</p>
                        </v-alert>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td class="text-sm-center">{{ item.id }}</td>
                                <td class="text-sm-center">{{ item.nombre }}</td>
                                <td class="justify-center layout px-0">
                                    <v-icon color="gray" @click="edit(item.id)">mdi-pencil-outline</v-icon>
                                    <v-icon color="red" @click="deleteMachine(item.id)">mdi-trash-can</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import headerVue from "../../components/header.vue"
    export default {
      components: {
        headerVue,
      },
      data() {
        return {
			deleteSuccess: false,
			loading: true,
          	search: "",
          headers: [
            {
              text: "Id",
              align: "center",
              sortable: false,
              value: "id",
            },
            { text: "Nombre", align: "center", value: "nombre" },
            { text: "Opciones", align: "center", sortable: false },
          ],
          equipmentType: [],
          back: "",
        }
      },
    	methods: {
			deleteMachine(id) {
				this.deleteSuccess = true;
				this.equipmentType = this.equipmentType.filter((equipmentType) => equipmentType.id !== id);

				axios.delete( 'https://consubanco.azurewebsites.net/api/EquipoTipo/' + id )
				.then(resp => {
					
					window.location.reload();
				})
				.catch(err => {
					
					console.warn(err);
				});
			},
			edit(id) {
				
				this.$router.push( "/editartipoequipo/"+ id );
			},
			inicialization() {

				axios.get( 'https://consubanco.azurewebsites.net/api/EquipoTipo' )
				.then(resp => {
					this.equipmentType = resp.data;
					this.loading = false;
				})
				.catch(err => {
					
					console.error(err);
				});
			},
    	},
		created() {
			this.inicialization();
		},
    }
</script>